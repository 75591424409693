<!-- 消息管理 -->
<template>
  <div id="message">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>视频识别告警</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <!-- <el-form-item label="接收人">
              <el-input placeholder="请输入接受人" v-model.trim="requestParam.recipientName" clearable></el-input>
            </el-form-item> -->

            <el-form-item label="告警级别">
              <el-select :popper-append-to-body="false" v-model="requestParam.levelId" placeholder="请选择告警级别" clearable>
                <el-option label="一般告警" value="1"></el-option>
                <el-option label="严重告警" value="2"></el-option>
                <el-option label="重大告警" value="3"></el-option>
                <el-option label="特别重大告警" value="4"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="开始时间">
              <el-date-picker placeholder="请选择开始时间" type="date" value-format="yyyy-MM-dd" clearable
                v-model="requestParam.createdAtStart" :append-to-body="false" @change="checkDate"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker placeholder="请选择结束时间" type="date" value-format="yyyy-MM-dd"
                v-model="requestParam.createdAtEnd" :picker-options="pickerOptions" :append-to-body="false"
                @change="checkDate"></el-date-picker>
            </el-form-item> -->
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
          </div>
        </div>

        <el-table class="table" :data="tableData" v-loading="loading" element-loading-text="加载中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.3)">
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="table-text">
                  {{ loading ? "" : "暂无数据" }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
                (requestParam.page - 1) * requestParam.pageSize +
                scope.$index +
                1
              }}</span>
            </template>
          </el-table-column>


          <el-table-column align="center" prop="stationNumber" label="场站编码" width="200">
          </el-table-column>
          <el-table-column align="center" prop="cameraName" label="摄像头名称" width="200">
          </el-table-column>
          <el-table-column align="center" prop="levelName" label="告警级别" width="200">
          </el-table-column>
          <el-table-column align="center" prop="algorithmName" label="告警类型">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="告警时间" width="160">
          </el-table-column>
          <el-table-column align="center" label="操作" prop="operate" fixed="right" width="200">
            <template slot-scope="scope">
              <div class="operate">
                <el-button type="text" @click="updateDialog(scope.row)">详情</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.page" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="dialogVisible" width="801px" @close="dialogClose">
      <div class="dialog-content">
        <el-form class="dialog-form" :inline="true" :model="detailsData">

          <el-form-item label="告警类型">
            <el-input v-model="detailsData.algorithmName" :readonly="true"></el-input>
          </el-form-item>

          <el-form-item label="告警时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="摄像头名称">
            <el-input v-model="detailsData.cameraName" :readonly="true"></el-input>
          </el-form-item>

          <!-- <el-form-item label="接收人">
            <el-input v-model="detailsData.recipientName" :readonly="true"></el-input>
          </el-form-item> -->

          <el-form-item label="告警图片">
            <el-image :preview-src-list="[detailsData.alarmUrl]" style="width: 200px; height: 200px" :src="detailsData.alarmUrl" :fit="fit"></el-image>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>
  </div>
</template>
<script>
// import { messageApi } from "@/api/message.js";
import { alarmApi } from "@/api/alarm.js";
import moment from "moment";
export default {
  name: "",

  data() {
    return {
      tableData: [],
      requestParam: {
        page: 1,
        pageSize: 10,
        levelId: "",
        stationNumber: '',

        // createdAtEnd: null,
        // createdAtStart: null,
      },
      total: 0,
      inputValue: "1",
      id: "",
      loading: false,
      dialogVisible: false,
      detailsData: {
        timestamp:''
      },

    };
  },

  created() {
    this.$store.commit("increment", "运行环境");
    this.$store.commit("selectChild", "视频识别告警");
    this.$store.commit("selectChildren", "");
    this.requestParam.stationNumber = sessionStorage.getItem("stationNumber");
  },
  mounted() {
    let selectedAlarm = this.$route.params;
    if (Object.keys(selectedAlarm).length !== 0) {
      this.detailsData = selectedAlarm;
      this.id = selectedAlarm.messageType;
      this.dialogVisible = true;
    }

    if (this.id) {
      this.requestParam.stationNumber = ''
      this.queryMessage();
    } else {
      if (this.$route.query == {}) {
        this.requestParam.stationNumber = ''
        this.queryMessage();
      } else {
        this.requestParam.stationNumber = sessionStorage.getItem("stationNumber");
        this.queryMessage(); //获取消息
      }

    }
  },
  methods: {
    formQuery() {
      this.requestParam.page = 1;
      this.queryMessage();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.page = 1;
      this.queryMessage();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.page = lastPage;
      this.queryMessage();
    },
    confirm() {
      this.requestParam.page = this.inputValue;
      this.queryMessage();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryMessage();
    },
    changeCurrent(page) {
      this.requestParam.page = page;
      this.queryMessage();
    },
    checkDate() {
      if (this.requestParam.createdAtStart && this.requestParam.createdAtEnd) {
        const startTime = new Date(this.requestParam.createdAtStart).getTime();
        const endTime = new Date(this.requestParam.createdAtEnd).getTime();
        if (startTime > endTime) {
          this.requestParam.createdAtEnd = null;
        }
      }
    },
    updateDialog(data) {
      // let time = new Date(data.timestamp * 1000);
      // let Y = time.getFullYear()
      // let M = (time.getMonth() + 1).toString().padStart(2, '0')
      // let D = time.getDate().toString().padStart(2, '0')
      // let h = time.getHours().toString().padStart(2, '0')
      // let m = time.getMinutes().toString().padStart(2, '0')
      // let s = time.getSeconds().toString().padStart(2, '0')
      // data.timestamp = `${Y}-${M}-${D} ${h}:${m}:${s}`
      //   this.detailsData.messageFlag === 1 ? "已读" : "未读";
      // this.detailsData.messageScope =
      //   this.detailsData.messageScope === 1 ? "PC" : "移动";
      this.id = data.messageId;
      this.detailsData = data;
      this.dialogVisible = true;
    },
    dialogClose() {
      if (this.detailsData.messageFlag === 2) {
        this.updateMessageStatus();
      }
      this.queryMessage()
    },
    queryMessage() {
      this.loading = true;
      alarmApi.videosMedia(this.requestParam).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    updateMessageStatus() {
      console.log("123");
      alarmApi.alarmRead(this.id).then((res) => {
        // this.$message({
        //   message: "修改成功",
        //   type: "success",
        //   duration: 3000,
        //   customClass: "messageText",
        // });
        // this.$store.dispatch("queryMessage");
        this.queryMessage();
      });
      // eventBus1.$emit('notices-updated-alarm');
    },
  },
  computed: {
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.requestParam.createdAtStart);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#message {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

/* .el-link.el-link--default{
color: #aed6ff;
}
.el-link.is-underline:hover:after{
  border-bottom: 1px solid #aed6ff;
} */
/* 执行结果 */
.dialog-content {
  margin: 20px 41px 0 41px;
}

.dialog-form :deep().el-form-item__label {
  width: 80px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}

.dialog-form :deep().el-form-item__label::after {
  content: ":";
  margin: 0 3px;
}

.dialog-form :deep() .el-form-item {
  margin: 0 0 16px 0;
}

.dialog-form :deep() .el-input {
  width: 257px;
}

.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea {
  width: 592px;
  background-color: #061b45;
  border: 1px solid #09295b;
  /* border: 0; */
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}
</style>
